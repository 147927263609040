@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

:root {
  --primary-color: #ff8900;
  --text-color: #0E0700;
  --text-color-disabled: #414141;
  --header-color: #000;
  --success-color: #0dff00;
}

body {
  margin: 0;
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Nunito', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4 {
  font-weight: 700;
  letter-spacing: 0.05em;
  color: var(--header-color);
}

p, a {
  color: var(--text-color);
}

a:hover {
  color: var(--primary-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

[data-theme="dark"] {
  --text-color: #F9F8F7;
  --text-color-disabled: #757575;
  --header-color: #FFF;
}

.clip-path-inset-0 {
  clip-path: inset(0);
}

.MuiDataGrid-main > div:nth-last-child(2) {
  display: none;
}